import { Link } from "react-router-dom";
import headerImage from "../../assets/Head 2022.v.2.png";
import artButtonImage from "../../assets/knapp-art_52368754253_o.png";
import homeButtonImage2 from "../../assets/knapp-hempil-02_52368831854_o.png";
import graphicDesignButtonImage from "../../assets/knapp-gproduction_52368940480_o.png";
import typographyIllustrationButtonImage from "../../assets/knapp-tillu_52368521291_o.png";
import styles from "./header.module.scss";
export const Header = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <div className={styles.headerImageContainer}>
            <img src={headerImage} alt="BokOmBok_Logo" />
          </div>
        </div>
        <div className={styles.navContainer}>
          <nav className={styles.nav}>
            <Link className={styles.homeLink} to="/">
              <div className={styles.imgContainer}>
                <img src={homeButtonImage2} alt="Home" />
              </div>
            </Link>
            <Link className={styles.link} to="/72177720302210714">
              <div className={styles.imgContainer}>
                <img
                  src={typographyIllustrationButtonImage}
                  alt="typography/Illustration"
                />
              </div>
            </Link>
            <Link className={styles.link} to="/72177720302210412">
              <div className={styles.imgContainer}>
                <img src={graphicDesignButtonImage} alt="Garaphic production" />
              </div>
            </Link>
            <Link className={styles.link} to="/72177720302210424">
              <div className={styles.imgContainer}>
                <img src={artButtonImage} alt="Art" />
              </div>
            </Link>
          </nav>
        </div>
      </div>
    </>
  );
};
