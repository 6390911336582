import { IImageModel } from "../../models/imageModel";
import styles from "./gallery.module.scss";

interface GalleryProps {
  images: IImageModel[];
}

export const Gallery = (props: GalleryProps) => {
  return (
    <>
      <div className={styles.galleryContainer}>
        {props.images.map((image) => {
          return (
            <div className={styles.imageContainer} key={image.id}>
              <img
                className={styles.image}
                src={image.imageUrl}
                alt={image.title}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
