import styles from "./loader.module.scss";
import loaderImage from "../../assets/52456271098_06d6a4663c_o.png";
export const Loader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={loaderImage} alt="Loader" className={styles.rotate} />
      </div>
    </div>
  );
};
