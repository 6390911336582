import { flickrImageModelsResponseResponse } from "../models/flickrImageModels";
import { get } from "./handleRequests";

export async function getImages(
  id: string
): Promise<flickrImageModelsResponseResponse> {
  const baseUrl =
    "https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=" +
    process.env.REACT_APP_API_KEY +
    "&photoset_id=" +
    id +
    "&user_id=196542173@N04&format=json&nojsoncallback=1";

  return (await get<flickrImageModelsResponseResponse>(baseUrl)).data;
}
