import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { IImageModel } from "../../../models/imageModel";
import { getImages } from "../../../services/galleryService";
import { Gallery } from "../../Gallery/Gallery";
import { Loader } from "../../loader/Loader";
import styles from "./dynamicPages.module.scss";

type IdParams = {
  flickrId: string;
};

export const DynamicPage = () => {
  const params = useParams<IdParams>();
  const [id, setId] = useState("");
  const [images, setImages] = useState<IImageModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setId(params.flickrId!);
  }, [params.flickrId]);

  useEffect(() => {
    if (id !== "") {
      setIsLoading(true);
      getImages(id)
        .then((data) => {
          const picUrls: IImageModel[] = [];
          data.photoset.photo.forEach((pic) => {
            let picUrl = `https://live.staticflickr.com/${pic.server}/${pic.id}_${pic.secret}.jpg`;
            picUrls.push(new IImageModel(picUrl, pic.title, pic.id));
          });
          setImages(picUrls);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  return (
    <>
      <div className={styles.container}>
        {isLoading ? <Loader /> : <Gallery images={images} />}
      </div>
    </>
  );
};
