import { useEffect, useState } from "react";
import { IImageModel } from "../../../models/imageModel";
import { getImages } from "../../../services/galleryService";
import { Gallery } from "../../Gallery/Gallery";
import { Loader } from "../../loader/Loader";
import styles from "./Home.module.scss";
export const Home = () => {
  const [images, setImages] = useState<IImageModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getImages("72177720302201165")
      .then((data) => {
        const picUrls: IImageModel[] = [];
        data.photoset.photo.forEach((pic) => {
          let picUrl = `https://live.staticflickr.com/${pic.server}/${pic.id}_${pic.secret}.jpg`;
          picUrls.push(new IImageModel(picUrl, pic.title, pic.id));
        });
        setImages(picUrls);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={styles.container}>
      {isLoading ? <Loader /> : <Gallery images={images} />}
    </div>
  );
};
